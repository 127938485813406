
html {
    body {
        font-size: 1.0rem;
    }

    body.OPERATOR {
        .ADMIN-ONLY {
            display: none !important;
        }
    }

    body.CUSTOMER {

        .OPERATOR-ONLY {
            display: none !important;
        }

        .ADMIN-ONLY {
            display: none !important;
        }
    }    
}

.hidden {
    display: none !important;
}

.logo img {
    margin-top: 20px;
    height: 180px;
}

.card-title {
    font-size: 22px;
}

.custom-select-sm {
    font-size: 1.0rem;
}

.menu-title {
    font-size: 16px;
}

#sidebar-menu ul {
    li {
        a {
            font-size: 23px;
        }

        &.active {
            background: #fff;
            
            a {
                color: #031e4b;
            }
    
        }
    }
}

.form-control {
    font-size: 1.0rem;
}

.btn {
    font-size: 1.0rem;
}

.header-profile-user {
    border: 1px solid #9d9d9d;
}

.progress {
    font-size: 1.0rem;
    height: 1.5rem;
}

.file-selector:last-child {
    border-top: 1px dotted #ddd;
}

.file-selector:first-child {
    border-top: none !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: #fdfdfd;
}

.table-striped tbody tr:nth-of-type(even) {
    background-color: #FFF;
}

.modal-xxl {
    max-width: 80%;
    height: 95%;

    .modal-content {
        height: 100%;
    }
}

doc-viewer-dialog {
    .modal-body {
        background-color: gray;
        text-align: center;
        padding: 0;
    }
    .modal-dialog-scrollable .modal-body {
        overflow-y: auto;        
        text-align: center;
        border: 1px solid #CCC;
        padding: 1rem;
    }
}

ivp-upload {
    border-top: 1px solid #c3c3c366;
    &:last-child {
        border-bottom: 1px solid #c3c3c366;
    }
}
.input-group-text {
    font-size: 1.0rem;
}